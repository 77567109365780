/** App.css */

header .MuiButtonBase-root {
  /* background-color: #00d1ef !important; */
  /* background-color: "rgba(118,96,145,0.2)"; */
  font-weight: bold;
  font-size: 22px;
}

body {
  background: #fff url("../assets/LOF_dapp_BG.JPG") center center;
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
@font-face {
  font-family: "Boldfinger";
  font-style: normal;
  font-weight: 400;
  src: local("Boldfinger"),
    url("https://fonts.cdnfonts.com/s/53821/Boldfinger.woff") format("woff");
}

.font-face-gm {
  font-family: "Boldfinger";
  font-size: 32px;
}
